<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title v-if="$auth.isAuthenticated">
        {{ this.$auth.user.email }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-skeleton-loader
        :loading="$auth.loading"
        type="button"
        transition="fade-transition"
      >
        <v-btn v-if="!$auth.isAuthenticated" @click="login" color="primary"
          >Log in</v-btn
        >
        <v-btn v-if="$auth.isAuthenticated" @click="logout" color="primary"
          >Log out
        </v-btn>
      </v-skeleton-loader>
    </v-app-bar>

    <v-main>
      <MoveCard />
    </v-main>
  </v-app>
</template>

<script>
import MoveCard from "./components/MoveCard";

export default {
  name: "App",
  components: {
    MoveCard
  },

  setup(_, { root }) {
    // Log the user in
    const login = () => {
      root.$auth.loginWithRedirect();
    };

    // Log the user out
    const logout = () => {
      root.$auth.logout({
        returnTo: window.location.origin
      });
    };
    return { login, logout };
  }
};
</script>
