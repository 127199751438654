<template>
  <MoveCard />
</template>

<script>
// @ is an alias to /src
import MoveCard from "@/components/MoveCard.vue";

export default {
  name: "Home",
  components: {
    MoveCard
  }
};
</script>
