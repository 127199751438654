<template>
  <v-container>
    <v-snackbar v-model="state.snackbar.success" color="success" bottom
      >Success!</v-snackbar
    >
    <v-snackbar v-model="state.snackbar.error" color="error" bottom
      >Something went wrong, try agian!</v-snackbar
    >
    <v-row justify="center">
      <v-col cols="12" xl="6" l="6" md="6" sm="12" xs="12">
        <!-- SELECT A GUN -->
        <v-card v-if="$auth.isAuthenticated" outlined class="mb-4">
          <!-- GUN TYPE TABS -->
          <v-tabs v-model="state.tab" fixed grow color="primary lighten-1">
            <v-tab key="0"> Fan guns </v-tab>
            <v-tab key="1"> Air/Water guns </v-tab>
          </v-tabs>

          <!-- TAB CONTENT -->
          <v-tabs-items v-model="state.tab">
            <!-- FANGUNS TAB -->
            <v-tab-item key="0">
              <v-card class="pt-8 pl-4 pr-4">
                <v-card-actions>
                  <v-autocomplete
                    v-model="state.fanguns.selectedLocation"
                    id="autocompleteFanguns"
                    placeholder="Select a fangun"
                    aria-label="Select a fangun"
                    :items="state.fanguns.guns"
                    :loading="state.loading.fanguns"
                    :disabled="state.loading.fanguns"
                    outlined
                    filled
                    clearable
                    @change="handleCloseKeyboard('autocompleteFanguns')"
                  />
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <!-- AIR/WATER GUNS TAB -->
            <v-tab-item key="1">
              <v-card class="pt-8 pl-4 pr-4">
                <v-card-actions>
                  <v-autocomplete
                    v-model="state.airwaterguns.selectedLocation"
                    id="autocompleteAirwaterguns"
                    placeholder="Select an air/water gun"
                    aria-label="Select an air/water gun"
                    :items="state.airwaterguns.guns"
                    :loading="state.loading.airwaterguns"
                    :disabled="state.loading.airwaterguns"
                    outlined
                    filled
                    clearable
                    @change="handleCloseKeyboard('autocompleteAirwaterguns')"
                  />
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>

        <!-- SELECT HYDRANT LOCATION -->
        <v-card
          v-if="$auth.isAuthenticated"
          class="pt-0 pb-2 pl-2 pr-2"
          outlined
        >
          <v-card-title>
            <v-spacer />
            <v-btn-toggle v-model="state.hydrants.mountain" mandatory>
              <v-btn :disabled="state.loading.hydrants"> Both </v-btn>
              <v-btn :disabled="state.loading.hydrants"> Whistler </v-btn>
              <v-btn :disabled="state.loading.hydrants"> Blackcomb </v-btn>
            </v-btn-toggle>
            <v-spacer />
          </v-card-title>
          <v-card-text class="pb-0">
            <v-autocomplete
              id="autocompleteHydrants"
              v-model="state.hydrants.selectedLocation"
              :placeholder="
                !state.gps.tracking
                  ? 'Select a location'
                  : 'Finding closest hydrant...'
              "
              :aria-label="
                !state.gps.tracking
                  ? 'Select a location'
                  : 'Finding closest hydrant...'
              "
              :items="computedHydrantsByMountain"
              :loading="state.loading.hydrants"
              :disabled="state.loading.hydrants"
              return-object
              outlined
              filled
              clearable
              @click:prepend="handleClosestLocation()"
              @change="handleCloseKeyboard('autocompleteHydrants')"
            >
              <template slot="prepend">
                <v-badge
                  v-if="state.gps.tracking"
                  color="error"
                  left
                  bordered
                  :offset-x="80"
                  :offset-y="70"
                  :content="
                    state.gps.accuracy
                      ? state.gps.accuracy + 'm accuracy'
                      : '..m accuracy'
                  "
                >
                  <v-icon
                    color="primary"
                    @click="handleClosestLocation()"
                    aria-label="Turn GPS off"
                  >
                    {{ mdiCrosshairsGps }}
                  </v-icon>
                </v-badge>

                <v-icon
                  v-if="!state.gps.tracking"
                  @click="handleClosestLocation()"
                  aria-label="Turn GPS on"
                >
                  {{ mdiCrosshairs }}
                </v-icon>
              </template>
            </v-autocomplete>
            <v-text-field
              outlined
              filled
              clearable
              label="Moved by"
              v-model="state.moved_by"
            >
              >
              <template slot="prepend">
                <v-icon aria-label="Moved by">
                  {{ mdiAccount }}
                </v-icon>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="ml-2 mr-2 pt-0">
            <v-spacer />
            <v-btn
              large
              color="error"
              :disabled="
                state.loading.hydrants ||
                state.loading.fanguns ||
                state.loading.airwaterguns
              "
              @click="handleCancel()"
            >
              Cancel
            </v-btn>
            <v-btn
              large
              color="primary"
              :disabled="
                (!state.fanguns.selectedLocation &&
                  !state.airwaterguns.selectedLocation) ||
                !state.hydrants.selectedLocation ||
                state.loading.submitting ||
                !state.moved_by
              "
              :loading="state.loading.submitting"
              @click="handleSubmit(state.tab)"
              >Move</v-btn
            ></v-card-actions
          >
        </v-card>

        <!-- AUTH LOADING -->
        <v-skeleton-loader
          v-if="!$auth.isAuthenticated"
          :loading="$auth.loading"
          type="image"
          height="100"
          transition="fade-transition"
        >
          <!-- NOT LOGGED IN -->
          <v-card
            v-if="!$auth.isAuthenticated"
            class="pt-6 pb-6 pl-2 pr-2"
            outlined
          >
            <v-card-text> Please sign in. </v-card-text>
          </v-card>
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onMounted, computed, reactive, watch } from "@vue/composition-api";

import { mdiCrosshairs, mdiCrosshairsGps, mdiClose, mdiAccount } from "@mdi/js";

import nearestPoint from "@turf/nearest-point";

import axios from "axios";

// Set the base API url
axios.defaults.baseURL =
  "https://api2.alchemymapping.com/vail/whistler/snowmaking";

export default {
  name: "MoveCard",
  setup(_, { root }) {
    const state = reactive({
      token: null,
      tab: null,
      loading: {
        submitting: false,
        hydrants: true,
        fanguns: true,
        airwaterguns: true
      },
      snackbar: {
        success: false,
        error: false
      },
      hydrants: {
        selectedLocation: null,
        locations: [],
        mountain: 0
      },
      fanguns: {
        selectedLocation: null,
        guns: []
      },
      airwaterguns: {
        selectedLocation: null,
        guns: []
      },
      gps: {
        tracking: false,
        watchId: null,
        location: [],
        accuracy: null,
        message: "getting location.."
      },
      moved_by: null
    });

    // GET hydrant data
    const getHydrants = async () => {
      state.loading.hydrants = true;

      const { data } = await axios.get("/hydrants").catch(() => {
        state.snackbar.error = true;
      });

      let locationsData = [];

      if (data) {
        // Push hydrnt_num and coordinates (value) keys into locations array
        data.features.map((hydrantItem) => {
          locationsData.push({
            text: hydrantItem.properties.hydrnt_num,
            value: hydrantItem.geometry.coordinates,
            mountain: hydrantItem.properties.mountain
          });
        });

        state.loading.hydrants = false;
      }

      state.hydrants.locations = locationsData.sort((a, b) =>
        a.text < b.text ? -1 : 1
      );
    };

    // GET fanguns data
    const getFanguns = async () => {
      state.loading.fanguns = true;

      const { data } = await axios.get("/fanguns/").catch(() => {
        state.snackbar.error = true;
      });

      let fangunsData = [];

      if (data) {
        // Push gun_num and coordinates (value) keys into fanguns array
        data.features.map((fangunItem) => {
          fangunsData.push({
            text: `${fangunItem.properties.gun_num} ${fangunItem.properties.gun_type} ${fangunItem.properties.gun_style}`,
            value: fangunItem.properties.id
          });
        });

        state.loading.fanguns = false;
      }
      state.fanguns.guns = fangunsData.sort((a, b) =>
        a.text < b.text ? -1 : 1
      );
    };

    // GET airwater guns data
    const getAirwaterguns = async () => {
      state.loading.airwaterguns = true;

      const { data } = await axios.get("/airwaterguns/").catch(() => {
        state.snackbar.error = true;
      });

      let airwatergunsData = [];

      if (data) {
        // Push gun_num and coordinates (value) keys into airwaterguns array
        data.features.map((airwatergunItem) => {
          airwatergunsData.push({
            text: `${airwatergunItem.properties.gun_number} (${airwatergunItem.properties.gun_style} ${airwatergunItem.properties.model})`,
            value: airwatergunItem.properties.id
          });
        });

        state.loading.airwaterguns = false;
      }
      state.airwaterguns.guns = airwatergunsData.sort((a, b) =>
        a.text < b.text ? -1 : 1
      );
    };

    // PATCH form data on submit button click
    const handleSubmit = async (selectedTab) => {
      state.loading.submitting = true;

      let selectedGunNum;
      let typeOfGun;

      if (selectedTab === 0) {
        selectedGunNum = state.fanguns.selectedLocation;
        typeOfGun = "fanguns";
      }

      if (selectedTab === 1) {
        selectedGunNum = state.airwaterguns.selectedLocation;
        typeOfGun = "airwaterguns";
      }

      let featureCollectionFan = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: state.hydrants.selectedLocation.value[0]
            },
            properties: {
              location: state.hydrants.selectedLocation.text,
              moved_by: state.moved_by
            }
          }
        ]
      };

      let featureCollectionAir = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: state.hydrants.selectedLocation.value[0]
            },
            properties: {
              gun_location: state.hydrants.selectedLocation.text,
              moved_by: state.moved_by
            }
          }
        ]
      };

      const { data } = await axios
        .patch(
          `https://api2.alchemymapping.com/vail/whistler/snowmaking/${typeOfGun}/${selectedGunNum}`,
          typeOfGun === "fanguns" ? featureCollectionFan : featureCollectionAir
        )
        .catch(() => {
          state.snackbar.error = true;
          state.loading.submitting = false;
        });

      if (data) {
        state.snackbar.success = true;

        state[typeOfGun].selectedLocation = null;

        state.loading.submitting = false;
      }
    };

    // Handle cancel button click
    const handleCancel = () => {
      if (state.gps.tracking) stopGps();
      state.fanguns.selectedLocation = null;
      state.airwaterguns.selectedLocation = null;
      state.hydrants.selectedLocation = null;
      state.moved_by = null;
    };

    // This forces software keyboards to close when called
    const handleCloseKeyboard = (el) => {
      document.getElementById(el).blur();
    };

    // Starts GPS tracking
    const startGps = () => {
      const options = {
        enableHighAccuracy: true,
        timeout: 30000,
        maximumAge: 0
      };
      state.gps.tracking = true;
      state.gps.watchId = navigator.geolocation.watchPosition(
        onGpsLock,
        onGpsError,
        options
      );
    };

    // Stops GPS tracking
    const stopGps = () => {
      navigator.geolocation.clearWatch(state.gps.watchId);
      state.gps.tracking = false;
    };

    // GPS error
    const onGpsError = () => {
      stopGps();
      state.snackbar.error = true;
    };

    // GPS success callback
    const onGpsLock = (position) => {
      // Stops GPS tracking after 30 seconds
      setTimeout(() => {
        stopGps();
      }, 30000);

      state.gps.accuracy = position.coords.accuracy;

      state.gps.location = [
        [position.coords.longitude, position.coords.latitude]
      ];

      // feature collection to hold all hydrant locations (turf needs a featureCollection)
      let featureCollection = {
        type: "FeatureCollection",
        features: []
      };

      // Iterate over hydrant locations and push features into featureCollection
      state.hydrants.locations.map((item) => {
        featureCollection.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: item.value[0]
          }
        });
      });

      // Use @turf/nearest-point to find the closest hydrant to gps coords
      const closestPoint = nearestPoint(
        state.gps.location[0],
        featureCollection
      );

      // Set the currently selected hydrant to the closet hydrant as determined by @turf/nearest-point
      state.hydrants.selectedLocation = {
        value: [closestPoint.geometry.coordinates]
      };
    };

    // Handle GPS icon click
    const handleClosestLocation = () => {
      if (!state.gps.tracking) {
        startGps();
      } else {
        stopGps();
      }
    };

    // Filter hydrants by mountain (or both)
    const computedHydrantsByMountain = computed(() => {
      switch (state.hydrants.mountain) {
        case 0:
          return state.hydrants.locations;
        case 1:
          return state.hydrants.locations.filter(
            (hydrantItem) => hydrantItem.mountain === "Whistler"
          );
        case 2:
          return state.hydrants.locations.filter(
            (hydrantItem) => hydrantItem.mountain === "Blackcomb"
          );
      }
    });

    onMounted(() => {
      console.log(
        "Proudly made by Mike Hamilton <mike@mikehamilton.ca> in Whistler, BC and/or Valencia, Spain."
      );

      root.$auth.auth0ClientPromise.then(() => {
        // Duurty hack for safari
        setTimeout(() => {
          root.$auth.getTokenSilently().then((res) => {
            // Set JWT token 'globally' for all axios calls
            axios.defaults.headers.common["Authorization"] = `Bearer ${res}`;

            // Get all the data for the autocompletes
            getHydrants();
            getFanguns();
            getAirwaterguns();
          });
        }, 2000);
      });
    });

    // Watch when tabs switch and reset selected guns autocomplete to null
    watch(
      () => state.tab,
      (oldVal, newVal) => {
        if (oldVal !== newVal) {
          state.fanguns.selectedLocation = null;
          state.airwaterguns.selectedLocation = null;
        }
      }
    );

    // Watch when selected mountain changes and null the selected hydrant
    watch(
      () => state.hydrants.mountain,
      (oldVal, newVal) => {
        if (oldVal !== newVal) {
          state.hydrants.selectedLocation = null;
        }
      }
    );

    return {
      state,
      getHydrants,
      getFanguns,
      getAirwaterguns,
      handleSubmit,
      handleCancel,
      handleClosestLocation,
      handleCloseKeyboard,
      computedHydrantsByMountain,
      mdiCrosshairs,
      mdiCrosshairsGps,
      mdiClose,
      mdiAccount
    };
  }
};
</script>
